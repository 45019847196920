import Vue from 'vue';
import api from 'api'; // import { aesDecrypt } from '@/utils/md'

import { setTitle } from 'utils/index';
/**
 * 错误处理
 * @param error
 * @param vm
 */

var errorHandler = function errorHandler(error, vm) {
  console.error(vm);
  console.error(error);
};

Vue.config.errorHandler = errorHandler;
export default {
  install: function install(Vue) {
    var _this = this;

    //  添加组件
    //  添加过滤器
    //  全局报错处理
    Vue.prototype.$throw = function (error) {
      return errorHandler(error, _this);
    };

    Vue.prototype.$http = api; // Vue.prototype.$aesDecrypt = aesDecrypt

    Vue.prototype.$setTitle = setTitle; // 其他配置
  }
};