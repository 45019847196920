function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import request from '@/utils/request'; // import { rsaEncryption } from '@/utils/md'

export default {
  /**
       * 购买套餐列表
       * @param payload
       */
  queryPurchaseList: function queryPurchaseList(payload) {
    return request({
      url: '/suit/order/buylist/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },

  /**
       * 套餐列表
       * @param payload
       */
  querySuitList: function querySuitList(payload) {
    return request({
      url: '/suit/config/list/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 购买套餐
  createSuitOrder: function createSuitOrder(payload) {
    return request({
      url: '/suit/order/create/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 购买套餐订单详情
  queryPaysuit: function queryPaysuit(payload) {
    return request({
      url: '/suit/order/detail/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },

  /**
       * 设置签章
       * @param payload
       */
  createSignature: function createSignature(payload) {
    return request({
      url: '/signature/create/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },

  /**
       * 签章身份验证
       * @param payload
       */
  verifySignatureIdentity: function verifySignatureIdentity(payload) {
    return request({
      url: '/signature/identity/verify/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 签章获取
  querySignatureDetail: function querySignatureDetail(payload) {
    return request({
      url: '/signature/detail/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 发送验证码 短信类型 1：签章短信，2：用户签约短信
  sendCommonCode: function sendCommonCode(payload) {
    return request({
      url: '/common/sms/sendCode/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 获取闪租企业信息
  queryCompanyInfo: function queryCompanyInfo(payload) {
    return request({
      url: '/common/account/initinfo/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 查询未支付订单
  queryUnpaidOrder: function queryUnpaidOrder(payload) {
    return request({
      url: '/suit/order/requnpaid/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 更改人脸识别开关
  updateFaceConfig: function updateFaceConfig(payload) {
    return request({
      url: '/account/face/setConfig/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 获取人脸识别开关状态
  queryFaceSwitch: function queryFaceSwitch(payload) {
    return request({
      url: '/sign/face/getConfig/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  }
};