//
//
//
//
//
//
//
//
//
// import defaultSetting from './settings'
export default {
  name: 'app',
  computed: {// transitionName () {
    //   // if (defaultSetting.needPageTrans) {
    //   //   return this.$store.state.direction
    //   // }
    //   return ''
    // },
    // includeRoutes() {
    //   return this.$store.state.routes.map(i => (i.keep && i.name)) || []
    // }
  },
  created: function created() {
    console.log('router', this.includeRoutes);
  }
};