function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import sniff from '@/libs/sniff';
/**
 * 判断浏览器
 * @return zfb wx other
 */

export var setTitle = function setTitle(title) {
  document.title = title;
  var i = document.createElement('iframe'); // i.src = '//pay.huizhaofang.com//activity/favicon.ico'

  i.style.display = 'none';

  i.onload = function () {
    setTimeout(function () {
      i.remove();
    }, 9);
  };

  document.body.appendChild(i);
};
export var checkBrowser = function checkBrowser() {
  var userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.match(/Alipay/i) === 'alipay') {
    return 'zfb';
  } else if (userAgent.match(/MicroMessenger/i) === 'micromessenger') {
    return 'wx';
  } else {
    return null;
  }
};
/**
 * 判断手机系统
 * @return android or ios
 */

export var checkPhone = function checkPhone() {
  var userAgent = window.navigator.userAgent;

  if (userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1) {
    return 'ANDROID'; // android
  }

  if (userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return 'IOS'; // ios
  }
};
export var getPlatForm = function getPlatForm() {
  if (sniff.wechat) {
    return 'WECHAT';
  } else if (sniff.miniapp) {
    return 'MINIAPP';
  } else if (sniff.iphone) {
    return 'IOS';
  } else if (sniff.android) {
    return 'ANDROID';
  } else {
    return 'OTHER';
  }
};
/**
 * 取url参数(单页应用可能有错误)
 * @param name param name
 * @return 参数 or null
 */

export var GetQueryString = function GetQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = window.location.search.substr(1).match(reg);
  if (r !== null) return unescape(r[2]);
  return null;
};
/**
 * 比较日期大小(-分隔)
 * @param d1
 * @param d2
 * @return 日期大
 */

export var CompareDate = function CompareDate(d1, d2) {
  if (new Date(d1.replace(/-/g, '/')) > new Date(d2.replace(/-/g, '/'))) {
    return d1;
  } else {
    return d2;
  }
};
/**
 * 小数向上取整
 * @param data 数据
 */

export var ToInt = function ToInt(data) {
  return Math.ceil(data);
};
/**
 * sleep延时函数
 * @param time 时间 豪秒数
 */

export var sleep = function sleep(time) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve();
    }, time);
  });
};
/**
 * 生成随机字符串（字母数字）
 * @return 字符串
 */

export var randomStr = function randomStr() {
  var text = '';
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < 8; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};
/**
 * 数组交集
 * @param {Array} arr1
 * @param {Array} arr2
 */

export var getCommonString = function getCommonString(arr1, arr2) {
  var len = Math.min(arr1.length, arr2.length);
  var i = -1;
  var res = [];

  while (++i < len) {
    var item = arr2[i];
    if (arr1.indexOf(item) > -1) res.push(item);
  }

  return res;
};
/**
 * 得到两个数组的交集, 两个数组的元素为数值或字符串
 * @param {Array} arr1
 * @param {Array} arr2
 */

export var getIntersection = function getIntersection(arr1, arr2) {
  return Array.from(new Set([].concat(_toConsumableArray(arr1), _toConsumableArray(arr2))));
};
/**
 * 判断要查询的数组是否至少有一个元素包含在目标数组中
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 */

export var hasOneOf = function hasOneOf(target, arr) {
  return target.some(function (_) {
    return arr.indexOf(_) > -1;
  });
};
/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */

export var isMillisecond = function isMillisecond(timeStamp) {
  var timeStr = String(timeStamp);
  return timeStr.length > 10;
};
/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} currentTime 当前时间时间戳
 * @returns {Boolean} 传入的时间戳是否早于当前时间戳
 */

export var isEarly = function isEarly(timeStamp, currentTime) {
  return timeStamp < currentTime;
};
/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */

export var getHandledValue = function getHandledValue(num) {
  return num < 10 ? '0' + num : num;
};
/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
 */

export var getDate = function getDate(timeStamp, startType) {
  var d = new Date(timeStamp * 1000);
  var year = d.getFullYear();
  var month = getHandledValue(d.getMonth() + 1);
  var date = getHandledValue(d.getDate());
  var hours = getHandledValue(d.getHours());
  var minutes = getHandledValue(d.getMinutes());
  var second = getHandledValue(d.getSeconds());
  var resStr = '';
  if (startType === 'year') resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second;else resStr = month + '-' + date + ' ' + hours + ':' + minutes;
  return resStr;
};
/**
 * @param {String|Number} timeStamp 时间戳
 * @returns {String} 相对时间字符串
 */

export var getRelativeTime = function getRelativeTime(timeStamp) {
  // 判断当前传入的时间戳是秒格式还是毫秒
  var IS_MILLISECOND = isMillisecond(timeStamp); // 如果是毫秒格式则转为秒格式

  if (IS_MILLISECOND) Math.floor(timeStamp /= 1000); // 传入的时间戳可以是数值或字符串类型，这里统一转为数值类型

  timeStamp = Number(timeStamp); // 获取当前时间时间戳

  var currentTime = Math.floor(Date.parse(new Date()) / 1000); // 判断传入时间戳是否早于当前时间戳

  var IS_EARLY = isEarly(timeStamp, currentTime); // 获取两个时间戳差值

  var diff = currentTime - timeStamp; // 如果IS_EARLY为false则差值取反

  if (!IS_EARLY) diff = -diff;
  var resStr = '';
  var dirStr = IS_EARLY ? '前' : '后'; // 少于等于59秒

  if (diff <= 59) resStr = diff + '秒' + dirStr; // 多于59秒，少于等于59分钟59秒
  else if (diff > 59 && diff <= 3599) resStr = Math.floor(diff / 60) + '分钟' + dirStr; // 多于59分钟59秒，少于等于23小时59分钟59秒
    else if (diff > 3599 && diff <= 86399) resStr = Math.floor(diff / 3600) + '小时' + dirStr; // 多于23小时59分钟59秒，少于等于29天59分钟59秒
      else if (diff > 86399 && diff <= 2623859) resStr = Math.floor(diff / 86400) + '天' + dirStr; // 多于29天59分钟59秒，少于364天23小时59分钟59秒，且传入的时间戳早于当前
        else if (diff > 2623859 && diff <= 31567859 && IS_EARLY) resStr = getDate(timeStamp);else resStr = getDate(timeStamp, 'year');
  return resStr;
};
/* 元转分 */

export var toFen = function toFen(value) {
  return parseInt((value - 0) * 100);
};
/* 分转元 */

export var toYuan = function toYuan(value) {
  var num = value + '';

  if (!num.length) {
    return '0.00';
  }

  if (isNaN(num)) {
    return num;
  } else {
    var r = num < 0 ? -1 : 1;
    num *= r; // 分转元

    num = r * (num < 1 ? num : "".concat(Math.floor(num / 100), ".").concat(num % 100 < 10 ? "0".concat(num % 100) : num % 100));
    num = Math.round(num * 100) / 100;
    var price = (num + '').split('.');
    price[1] = price[1] ? "".concat((price[1] + '000').substring(0, 2)) : '00';
    return price.join('.');
  }
}; // 千分位分割

export var moneyFiletr = function moneyFiletr(value) {
  var num = value + '';

  if (!num.length) {
    return '0.00';
  }

  if (isNaN(num)) {
    return num;
  } else {
    var r = num < 0 ? -1 : 1;
    num *= r; // 分转元

    num = r * (num < 1 ? num : "".concat(Math.floor(num / 100), ".").concat(num % 100 < 10 ? "0".concat(num % 100) : num % 100));
    num = Math.round(num * 100) / 100;
    var price = (num + '').split('.');
    price[0] = price[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    price[1] = price[1] ? "".concat((price[1] + '000').substring(0, 2)) : '00';
    return price.join('.');
  }
};
/**
 * 手机号格式化
 * @param {String} phone
 */

export var formatPhone = function formatPhone(phone) {
  console.log('phone', phone);
  var phoneStr = phone + '';
  return phoneStr.substr(0, 3) + '****' + phoneStr.substr(7, 11);
};