import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/panel/style";
import _Panel from "vant/es/panel";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/number-keyboard/style";
import _NumberKeyboard from "vant/es/number-keyboard";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/skeleton/style";
import _Skeleton from "vant/es/skeleton";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/datetime-picker/style";
import _DatetimePicker from "vant/es/datetime-picker";
import "vant/es/collapse/style";
import _Collapse from "vant/es/collapse";
import "vant/es/collapse-item/style";
import _CollapseItem from "vant/es/collapse-item";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/checkbox-group/style";
import _CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/dropdown-menu/style";
import _DropdownMenu from "vant/es/dropdown-menu";
import "vant/es/dropdown-item/style";
import _DropdownItem from "vant/es/dropdown-item";
import "vant/es/password-input/style";
import _PasswordInput from "vant/es/password-input";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";
import "vant/es/pull-refresh/style";
import _PullRefresh from "vant/es/pull-refresh";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/switch/style";
import _Switch from "vant/es/switch";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/col/style";
import _Col from "vant/es/col";
import "vant/es/row/style";
import _Row from "vant/es/row";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import Vue from 'vue';
import 'vant/lib/index.css';
Vue.use(_Cell).use(_CellGroup).use(_Panel).use(_Button).use(_Loading).use(_NumberKeyboard).use(_Popup).use(_Tabbar).use(_TabbarItem).use(_NavBar).use(_Icon).use(_Toast).use(_Dialog).use(_Skeleton).use(_Picker).use(_DatetimePicker).use(_Collapse).use(_CollapseItem).use(_Checkbox).use(_CheckboxGroup).use(_List).use(_DropdownMenu).use(_DropdownItem).use(_PasswordInput).use(_Field).use(_RadioGroup).use(_Radio).use(_CountDown).use(_PullRefresh).use(_Tabs).use(_Tab).use(_Search).use(_Switch).use(_Uploader).use(_Overlay).use(_Col).use(_Row).use(_ActionSheet);