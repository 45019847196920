export default [{
  path: '/home',
  name: 'home',
  component: function component() {
    return import(
    /* webpackChunkName: "home" */
    '@/pages/home/index.vue');
  },
  meta: {
    title: '合同列表',
    keepAlive: true,
    auth: false
  }
}, {
  path: '/addcontract',
  name: 'addcontract',
  component: function component() {
    return import(
    /* webpackChunkName: "addcontract" */
    '@/pages/contract/index.vue');
  },
  meta: {
    title: '录入信息',
    keepAlive: true,
    auth: false
  }
}, {
  path: '/signContract',
  name: 'signContract',
  component: function component() {
    return import(
    /* webpackChunkName: "signContract" */
    '@/pages/contract/signContract.vue');
  },
  meta: {
    title: '查看电子合同',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/generateContract',
  name: 'generateContract',
  component: function component() {
    return import(
    /* webpackChunkName: "generateContract" */
    '@/pages/contract/generateContract.vue');
  },
  meta: {
    title: '签约地址',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/certification',
  name: 'certification',
  component: function component() {
    return import(
    /* webpackChunkName: "certification" */
    '@/pages/certification/index.vue');
  },
  meta: {
    title: '信息确认页',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/previewContract',
  name: 'previewContract',
  component: function component() {
    return import(
    /* webpackChunkName: "previewContract" */
    '@/pages/certification/previewContract.vue');
  },
  meta: {
    title: '合同签署',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/identitying',
  name: 'identitying',
  component: function component() {
    return import(
    /* webpackChunkName: "identitying" */
    '@/pages/certification/identitying.vue');
  },
  meta: {
    title: '合同签署',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/signedSuccess',
  name: 'signedSuccess',
  component: function component() {
    return import(
    /* webpackChunkName: "signedSuccess" */
    '@/pages/signResult/signed.vue');
  },
  meta: {
    title: '合同签署',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/signeDetail',
  name: 'signeDetail',
  component: function component() {
    return import(
    /* webpackChunkName: "signeDetail" */
    '@/pages/signResult/signDetail.vue');
  },
  meta: {
    title: '查看合同',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/direaction',
  name: 'direaction',
  component: function component() {
    return import(
    /* webpackChunkName: "direaction" */
    '@/pages/home/direaction.vue');
  },
  meta: {
    title: '使用说明',
    keepAlive: false,
    auth: false
  }
}];