import Vue from 'vue';
import sniff from '../libs/sniff';
Vue.directive('xbottom', {
  // 注意： 在每个函数中， 第一个参数永远是el， 表示被绑定了指令的那个元素，这个el参数，是一个原生的JS对象
  // 每当指令绑定到元素上的时候，会立即执行这个bind函数，只执行一次
  bind: function bind(el) {
    console.log('el', el, sniff.iphoneX);

    if (sniff.iphoneX) {// el.style.height = '98px'
      // el.style.paddingBottom = '34px'
      // el.style.background = 'red'
      // console.log(' paddingBottom', el.style.height)
    }
  }
});
Vue.directive('xback', {
  bind: function bind(el) {
    console.log('el', el, sniff.iphoneX);

    if (sniff.iphoneX) {
      el.style.bottom = '102px';
    }
  }
});
Vue.directive('xbacktop', {
  bind: function bind(el) {
    console.log('el', el, sniff.iphoneX);

    if (sniff.iphoneX) {
      el.style.bottom = '180px';
    }
  }
});