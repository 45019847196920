function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import dayjs from 'dayjs';
/**
 * 格式化时间戳（秒|毫秒）
 * @param {timestamp} value
 */

var timeFilter = function timeFilter(value) {
  var nowValue = value.toString();

  if (nowValue) {
    if (nowValue.length === 13) {
      return dayjs(Number(nowValue)).format('YYYY-MM-DD HH:mm:ss');
    }

    return dayjs.unix(Number(nowValue)).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return '-';
  }
};
/**
 * 手机号格式化
 * @param {String} phone
 */


var formatPhone = function formatPhone(phone) {
  if (phone) {
    var phoneStr = phone.toString();
    return phoneStr.substr(0, 3) + '****' + phoneStr.substr(7, 11);
  }

  return phone;
};
/**
 * 4位一空格（格式化银行卡）
 * @param {String} val
 */


var formatBank = function formatBank(val) {
  if (val) {
    return val.toString().replace(/\s/g, '').replace(/(.{4})/g, '$1 ');
  }
};
/**
 * 千分位格式化
 * @param {数字} val
 */


var toThousands = function toThousands(val) {
  var num = (val || 0).toString();
  var result = '';

  while (num.length > 3) {
    result = ',' + num.slice(-3) + result;
    num = num.slice(0, num.length - 3);
  }

  if (num) {
    result = num + result;
  }

  return result;
};
/**
 * 格式化小数位
 * @param val 传入的值
 * @param pos 保留的小数位
 * @returns {*}
 */


var formatFloat = function formatFloat(val) {
  var pos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var f = parseFloat(val);

  if (isNaN(f)) {
    return false;
  }

  f = Math.round(val * Math.pow(10, pos)) / Math.pow(10, pos); // pow 幂

  var s = f.toString();
  var rs = s.indexOf('.');

  if (rs < 0) {
    rs = s.length;
    s += '.';
  }

  while (s.length <= rs + pos) {
    s += '0';
  }

  return s;
};
/**
 * 金额
 * @param {*} val
 */


var formatMoney = function formatMoney(val) {
  if (!val || val <= 0) return val;
  var showVal = val / 100;
  return formatFloat(showVal);
};
/**
 * 格式化时长
 * @param second
 * @returns {string}
 */


var realFormatSecond = function realFormatSecond(second) {
  var secondType = _typeof(second);

  if (secondType === 'number' || secondType === 'string') {
    second = parseInt(second);
    var hours = Math.floor(second / 3600);
    second = second - hours * 3600;
    var mimute = Math.floor(second / 60);
    second = second - mimute * 60;
    return hours + ':' + ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2);
  } else {
    return '0:00:00';
  }
};
/* 元转分 */


var toFen = function toFen(value) {
  return parseInt((value - 0) * 100);
};
/* 分转元 */


var toYuan = function toYuan(value) {
  var num = value + '';

  if (!num.length) {
    return '0.00';
  }

  if (isNaN(num)) {
    return num;
  } else {
    var r = num < 0 ? -1 : 1;
    num *= r; // 分转元

    num = r * (num < 1 ? num : "".concat(Math.floor(num / 100), ".").concat(num % 100 < 10 ? "0".concat(num % 100) : num % 100));
    num = Math.round(num * 100) / 100;
    var price = (num + '').split('.');
    price[1] = price[1] ? "".concat((price[1] + '000').substring(0, 2)) : '00';
    return price.join('.');
  }
}; // 千分位分割


var moneyFiletr = function moneyFiletr(value) {
  var num = value + '';

  if (!num.length) {
    return '0.00';
  }

  if (isNaN(num)) {
    return num;
  } else {
    var r = num < 0 ? -1 : 1;
    num *= r; // 分转元

    num = r * (num < 1 ? num : "".concat(Math.floor(num / 100), ".").concat(num % 100 < 10 ? "0".concat(num % 100) : num % 100));
    num = Math.round(num * 100) / 100;
    var price = (num + '').split('.');
    price[0] = price[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    price[1] = price[1] ? "".concat((price[1] + '000').substring(0, 2)) : '00';
    return price.join('.');
  }
}; // 保留两位小数


var retainTwoDecimal = function retainTwoDecimal(value) {
  var num = value + '';

  if (!num.length) {
    return '0.00';
  }

  if (isNaN(num)) {
    return num;
  } else {
    var price = (num + '').split('.');
    price[1] = price[1] ? "".concat((price[1] + '000').substring(0, 2)) : '00';
    return price.join('.');
  }
};

export default {
  timeFilter: timeFilter,
  formatPhone: formatPhone,
  formatBank: formatBank,
  toThousands: toThousands,
  formatFloat: formatFloat,
  realFormatSecond: realFormatSecond,
  formatMoney: formatMoney,
  toFen: toFen,
  toYuan: toYuan,
  moneyFiletr: moneyFiletr,
  retainTwoDecimal: retainTwoDecimal
};