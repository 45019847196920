function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);
var state = {
  userInfo: {}
};
var mutations = {
  SET_USERINFO: function SET_USERINFO(state, content) {
    if (content) state.userInfo = content;
  },
  SET_KEEP_ROUTES: function SET_KEEP_ROUTES(state, payload) {
    state.routes = payload;
  },
  UPDATE_KEEP_ROUTES: function UPDATE_KEEP_ROUTES(state, payload) {
    state.routes = state.routes.map(function (i) {
      return _objectSpread(_objectSpread({}, i), {}, {
        keep: i.name !== payload
      });
    });
  },
  REMOVE_KEEP_ROUTES: function REMOVE_KEEP_ROUTES(state, payload) {
    state.routes = state.routes.map(function (i) {
      return _objectSpread(_objectSpread({}, i), {}, {
        keep: !!payload.includes(i.name)
      });
    });
  },
  RESET_KEEP_ROUTES: function RESET_KEEP_ROUTES(state) {
    state.routes = state.routes.map(function (i) {
      return _objectSpread(_objectSpread({}, i), {}, {
        keep: true
      });
    });
  }
};
var getters = {
  getUserInfo: function getUserInfo(state) {
    return state.userInfo;
  }
};
var Store = new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters
});
export default Store;