import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import { setTitle } from 'utils';
import store from '@/store';
Vue.use(VueRouter);
var routes = [{
  path: '/',
  name: 'home',
  component: function component() {
    return import(
    /* webpackChunkName: "home" */
    '@/pages/home/index.vue');
  },
  meta: {
    title: '合同列表',
    keepAlive: true,
    auth: false
  }
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return import(
    /* webpackChunkName: "404" */
    '@/pages/error/404.vue');
  },
  meta: {
    title: '404',
    keepAlive: true,
    auth: false
  }
}];

var routerContext = require.context('./', true, /\.js$/);

routerContext.keys().forEach(function (route) {
  // 如果是根目录的index.js 不处理
  if (route.startsWith('./index')) return;
  var routerModule = routerContext(route); // 兼容import export and require module.export 两种规范

  routes = routes.concat(routerModule.default || routerModule);
});
routes = routes.concat({
  path: '*',
  name: '404',
  component: function component() {
    return import(
    /* webpackChunkName: "404" */
    '@/pages/error/404.vue');
  },
  meta: {
    title: '404',
    keepAlive: true,
    auth: false
  }
});

var createRouter = function createRouter() {
  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: routes
  });
};

var myRouter = createRouter();
var history = window.sessionStorage;
history.clear(); // let historyCount = history.getItem('count') * 1 || 0

history.setItem('/', 0);
myRouter.beforeEach(function (to, from, next, redirect) {
  NProgress.start(); // 设置页面title

  var _to$meta$title = to.meta.title,
      title = _to$meta$title === void 0 ? '签约工具' : _to$meta$title;
  console.log('title', title);
  setTitle(title); // console.log('to', )
  // 拦截
  // if (to.path === '/') {
  //   const { token, status = '1' } = to.query
  //   if (!token) {
  //     return next('/404')
  //   }
  //   if (status.toString() === '0') {
  //     return next({ path: '/open', query: to.query })
  //   } else {
  //     return next()
  //   }
  // } else {
  //   next()
  // }

  next();
});
myRouter.afterEach(function (transition) {
  NProgress.done();
}); // 加载路由文件容错处理

myRouter.onError(function (error) {
  var pattern = /Loading chunk (\d)+ failed/g;
  var isChunkLoadFailed = error.message.match(pattern);
  var targetPath = myRouter.history.pending.fullPath;
  myRouter.replace(targetPath);
  console.log('router error', pattern, isChunkLoadFailed, error); // if (isChunkLoadFailed) {
  //   myRouter.replace(targetPath)
  // } else {
  //   console.log('router error', error)
  // }
});
var keepRouterNames = routes.filter(function (item) {
  return item.meta.keepAlive;
}).map(function (item) {
  return {
    name: item.name,
    keep: true
  };
});
console.log('rouressssss', keepRouterNames);
store.commit('SET_KEEP_ROUTES', keepRouterNames);
export function resetRouter() {
  myRouter.replace('/login');
}
export default myRouter;