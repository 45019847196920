function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import request from '@/utils/request';
export default {
  /**
       * 账户详情
       * @param payload
       */
  queryAccountDetail: function queryAccountDetail(payload) {
    return request({
      url: '/account/detail/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },

  /**
       * 合同列表
       * @param payload
       */
  queryContractList: function queryContractList(payload) {
    return request({
      url: '/contract/list/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 删除合同
  deleteContract: function deleteContract(payload) {
    return request({
      url: '/contract/delete/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 显示购买按钮
  showPurchaseBtn: function showPurchaseBtn(payload) {
    return request({
      url: '/common/view/config/',
      method: 'POST',
      toastLoading: false,
      data: _objectSpread({}, payload)
    });
  },
  // 获取签约详情
  querySignDetail: function querySignDetail(payload) {
    return request({
      url: '/sign/detail/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 传递签字图片
  createSign: function createSign(payload) {
    return request({
      url: '/sign/create/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 签约合同预览
  signPreview: function signPreview(payload) {
    return request({
      url: '/contract/sign/preview/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 人脸参数获取
  getFaceParams: function getFaceParams(payload) {
    return request({
      url: '/face/reqparams/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  sendResult: function sendResult(payload) {
    return request({
      url: '/face/saveresult/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 获取认证结果
  getFaceResult: function getFaceResult(payload) {
    return request({
      url: '/face/reqverifyresult/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 上传合同信息
  postContractInfo: function postContractInfo(payload) {
    return request({
      url: '/contract/esignature/setposition/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 生成合同
  createContract: function createContract(payload) {
    return request({
      url: '/contract/create/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 合同详情
  contractDetail: function contractDetail(payload) {
    return request({
      url: '/contract/detail/',
      method: 'POST',
      data: _objectSpread({}, payload)
    });
  },
  // 上传文件
  uploadFile: function uploadFile(payload) {
    return request({
      url: '/common/uploadfile/',
      method: 'POST',
      dataType: 'multipart',
      data: payload
    });
  }
};