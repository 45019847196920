export default [{
  path: '/login',
  name: 'login',
  component: function component() {
    return import(
    /* webpackChunkName: "wallet" */
    '@/pages/login/index.vue');
  },
  meta: {
    title: '个人页',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/purchase',
  name: 'purchase',
  component: function component() {
    return import(
    /* webpackChunkName: "purchase" */
    '@/pages/purchase/index.vue');
  },
  meta: {
    title: '购买套餐',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/purchaseHistory',
  name: 'purchaseHistory',
  component: function component() {
    return import(
    /* webpackChunkName: "purchaseHistory" */
    '@/pages/purchase/history.vue');
  },
  meta: {
    title: '购买记录',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/confirmPurchase',
  name: 'confirmPurchase',
  component: function component() {
    return import(
    /* webpackChunkName: "purchaseHistory" */
    '@/pages/purchase/confirmPurchase.vue');
  },
  meta: {
    title: '支付确认',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/purchaseSuccess',
  name: 'purchaseSuccess',
  component: function component() {
    return import(
    /* webpackChunkName: "purchaseSuccess" */
    '@/pages/purchase/purchaseSuccess.vue');
  },
  meta: {
    title: '支付确认',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/sign',
  name: 'sign',
  component: function component() {
    return import(
    /* webpackChunkName: "sign" */
    '@/pages/sign/index.vue');
  },
  meta: {
    title: '设置签章',
    keepAlive: true,
    auth: false
  }
}, {
  path: '/caputer',
  name: 'caputer',
  component: function component() {
    return import(
    /* webpackChunkName: "sign" */
    '@/pages/sign/caputer.vue');
  },
  meta: {
    title: '设置签章',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/signResult',
  name: 'signResult',
  component: function component() {
    return import(
    /* webpackChunkName: "sign" */
    '@/pages/sign/signResult.vue');
  },
  meta: {
    title: '设置签章',
    keepAlive: false,
    auth: false
  }
}];