import "vant/es/toast/style";
import _Toast from "vant/es/toast";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import axios from 'axios';
import qs from 'qs'; // import store from '@/store'
// const { CancelToken } = axios

window.cancelRequest = new Map();

var _axios = axios.create({
  timeout: 10000
}); // _axios.defaults.baseURL = process.env.VUE_APP_BASE_API


_axios.defaults.baseURL = process.env.VUE_APP_PROXY_API;
console.log('process', process.env, process.VUE_APP_PROXY_API);

_axios.interceptors.request.use(function (config) {
  var headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  };
  var data = config.data || {};

  if (data.token) {
    headers['Hyx-Token'] = data.token;
  }

  delete data.token;

  if (config['dataType'] === 'JSON') {
    headers['Content-Type'] = 'application/json; charset=UTF-8';
    config['data'] = qs.parse(data);
  } else if (config['dataType'] === 'multipart') {
    headers['Content-Type'] = 'multipart/form-data; charset=UTF-8'; // Object.assign(config, {
    //   withCredentials: false,
    //   transformRequest: data => qs.stringify(config.data) // 仅支持put，post、patch请求
    // })
  } else {
    Object.assign(config, {
      withCredentials: false,
      transformRequest: function transformRequest(data) {
        return qs.stringify(data);
      } // 仅支持put，post、patch请求

    });
  }

  config.headers = headers;
  return config;
}, function (err) {
  return Promise.reject(err);
});

export default function request(options) {
  var _options$loading = options.loading,
      loading = _options$loading === void 0 ? true : _options$loading,
      _options$toastLoading = options.toastLoading,
      toastLoading = _options$toastLoading === void 0 ? true : _options$toastLoading;
  var loadingInstance = null;

  if (loading) {
    loadingInstance = _Toast.loading({
      duration: 0,
      // 持续展示 toast
      forbidClick: true,
      message: '加载中'
    });
  }

  return new Promise(function (resolve, reject) {
    _axios(options).then(function (res) {
      loadingInstance && loadingInstance.close();

      if (res.data.status.code !== '0') {
        console.log('toastLoading', toastLoading);

        if (toastLoading) {
          _Toast(res.data.status.description);
        }

        resolve(res.data);
      } // 当 responseType 为blob 的时候，返回结果会被转成blob


      if (res.data && (res.data instanceof Blob || typeof res.data === 'string')) {
        // 如果响应报错，此时后端返回的json 信息会转换成blob，为了拿到具体信息需要把他转成json 对象
        if (res.data.type === 'application/json') {
          var reader = new FileReader();

          reader.onload = function (e) {
            var json = JSON.parse(e.target.result);

            _Toast.error(json.status.description, false);

            resolve(_objectSpread({
              success: false
            }, json));
          };

          reader.readAsText(res.data);
        } else {
          // 拿到的是正常的 blob 文件，直接resolve 成功
          resolve({
            success: true,
            result: res.data
          });
        }
      }

      var dt = _objectSpread({}, res.data); // 这里由于接口有多种数据格式，所以做了两种判断，为了个页面少写一个code 特意做了转换成success字段，只需判断success即可


      if (res.data.code === '0') {
        dt['success'] = true;
        resolve(dt);
      } else if (res.data.status) {
        dt = _objectSpread({}, res.data);

        if (res.data.status.code === '0') {
          dt['success'] = true;
          resolve(dt);
        } else {
          resolve(dt);
        }
      } else {
        reject(dt);
      }
    }).catch(function (error) {
      loadingInstance && loadingInstance.close();
      var errorStr = String(error);

      if (/4[0-9]{2}/.test(errorStr)) {
        _Toast('接口不可用！');
      }

      if (/5[0-9]{2}/.test(errorStr)) {
        _Toast('服务器忙，请稍后再试！');
      }

      if (errorStr.indexOf('timeout') > -1) {
        _Toast('请求超时,请稍后再试！');
      }

      return Promise.reject(error);
    });
  });
}